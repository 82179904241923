<template>
  <div class="game-container">
    <MatchTheCardsGame :imgList="imgList" @changeCardBG="changeCardBG"/>
  </div>
</template>

<script>
import MatchTheCardsGame from "@/components/Course/GamePage/MatchTheCardsGame";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-43/man-food.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-43/baby-food.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-43/cat-food.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-43/baby.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-43/man.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-43/cat.svg"),
          type: 0,
          cardActive: false,
        },
      ],
    };
  },
  components: {
    MatchTheCardsGame,
  },
  methods: {
    changeCardBG(index, firstIndex, type) {
      this.imgList[index].type = type;
      this.imgList[index].cardActive = false;
      this.imgList[firstIndex].type = type;
      this.imgList[firstIndex].cardActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>












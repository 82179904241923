<template>
  <div class="game-container">
    <FindElementGroupGame
      :imgList="imgList"
      :bgImgArr="bgImgArr"
      :lessonNo="43"
      :buttonList="buttonList"
      :titleInfoList="titleInfoList"
    />
  </div>
</template>

<script>
import FindElementGroupGame from "@/components/Course/GamePage/FindElementGroupGame";
export default {
  data() {
    return {
      titleInfoList: [
        {
          pinyin: "shuǐ",
          hanzi: "水",
        },
        {
          pinyin: "niúnǎi",
          hanzi: "牛奶",
        },
      ],
      bgImgArr: [
        require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/background.svg"),
        require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/background.svg"),
      ],
      imgList: [
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/water-flowers.svg"),
            index: 1,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/water-sink.svg"),
            index: 2,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/water-puddle.svg"),
            index: 3,
          },
        ],
        [
          {
            id: 1,
            image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/milk-bottle.svg"),
            index: 4,
          },
          {
            id: 2,
            image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/milk-jug.svg"),
            index: 5,
          },
          {
            id: 3,
            image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/milk-pack.svg"),
            index: 6,
          },
        ],
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-43-1/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
      ],
    };
  },
  components: {
    FindElementGroupGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>

import { render, staticRenderFns } from "./5_StoryPageOne.vue?vue&type=template&id=3c98aeb4&scoped=true"
import script from "./5_StoryPageOne.vue?vue&type=script&lang=js"
export * from "./5_StoryPageOne.vue?vue&type=script&lang=js"
import style0 from "./5_StoryPageOne.vue?vue&type=style&index=0&id=3c98aeb4&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c98aeb4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/nihaokidsNew-front_BmM9/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c98aeb4')) {
      api.createRecord('3c98aeb4', component.options)
    } else {
      api.reload('3c98aeb4', component.options)
    }
    module.hot.accept("./5_StoryPageOne.vue?vue&type=template&id=3c98aeb4&scoped=true", function () {
      api.rerender('3c98aeb4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Course/Level1/Chapter6/Lesson43/5_StoryPageOne.vue"
export default component.exports
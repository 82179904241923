<template>
  <div class="find-element-group-game-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNo">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex - 1"
        @changeTheNumbers="changeTheNumbers"
      />
      <div class="title-area" v-if="curTitleInfo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ curTitleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ curTitleInfo.hanzi }}
          </div>
        </div>
      </div>
      <img :src="bgImgArr[currentIndex]" class="bg-img" />
      <div
        class="img-area"
        v-for="(item, index) in imgList[currentIndex]"
        :key="index"
        v-show="findIdList.indexOf(item.id) !== -1"
      >
        <img :src="item.image" alt="" />
      </div>
      <div class="click-area">
        <div
          class="click-item"
          v-for="(item, index) in imgList[currentIndex]"
          :class="'click-' + item.index"
          :key="index + 'click'"
          @click="handleClick(item.id)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";

export default {
  name: "FindElementGroupGame",
  props: {
    imgList: {
      type: Array,
      require: true,
      default: () => [],
    },
    bgImgArr: {
      type: Array,
      require: true,
    },
    lessonNo: {
      type: Number,
    },
    buttonList: {
      type: Array,
      require: true,
      defalut: () => [],
    },
    titleInfoList: {
      type: Array,
      require: false,
    },
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  data() {
    return {
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 1,
            endNumberRange: 100,
          },
        ],
      },
      findIdList: [],
      clickSocketInfo: {},
      clickMenuSocketInfo: {},
      currentIndex: 0,
      curTitleInfo: {},
    };
  },
  watch: {
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 20064,
          data: { value },
          text: "FamilyTreeGame drop socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006401,
          data: { value },
          text: "FindElementGroup菜单栏按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  created() {
    this.curTitleInfo = this.titleInfoList[0];
  },
  mounted() {
    this.$bus.$on("clickToFindElement", ({ id }) => {
      this.handleClick(id, true);
    });
    this.$bus.$on("FindElementGroupMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("clickToFindElement");
    this.$bus.$off("FindElementGroupMenu");
  },
  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      // console.log(index);
      this.currentIndex = index - 1;
      this.findIdList = [];
      this.curTitleInfo = this.titleInfoList[index - 1];
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClick(id, isFromSocket) {
      if (!isFromSocket) {
        this.clickSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      if (this.findIdList.indexOf(id) !== -1) {
        return;
      }
      this.findIdList.push(id);
      // this.findIdList = Array.from(new Set(this.findIdList));
      playCorrectSound(true, false);
      if (
        this.findIdList.length === this.imgList[this.currentIndex].length &&
        this.currentIndex === this.imgList.length - 1
      ) {
        playCorrectSound();
        startConfetti();
        this.isLastStep = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.find-element-group-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 10%;
      height: auto;
      padding: 20px;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }
    .bg-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 58px;
      z-index: 2;
    }
    .img-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: 4;
      }
    }
    .click-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      .click-item {
        position: absolute;
        // background: #000;
        width: 20%;
        height: 10%;
        left: 0;
        top: 0;
        cursor: pointer;
      }
    }
  }
}

.lesson-43 {
  .click-1 {
    width: 9% !important;
    height: 23% !important;
    left: 7% !important;
    top: 29% !important;
  }
  .click-2 {
    width: 16% !important;
    height: 16% !important;
    left: 22% !important;
    top: 39% !important;
  }
  .click-3 {
    width: 16% !important;
    height: 11% !important;
    left: 32% !important;
    top: 85% !important;
  }
  .click-4 {
    width: 4% !important;
    height: 10% !important;
    left: 80% !important;
    top: 59% !important;
  }
  .click-5 {
    width: 7% !important;
    height: 10% !important;
    left: 67% !important;
    top: 69% !important;
  }
  .click-6 {
    width: 8% !important;
    height: 17% !important;
    left: 24% !important;
    top: 9% !important;
  }
}
</style>
